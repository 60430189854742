const filters = {
    id: null,
    descricao: null,
    tipo: [],
    createdAt: null,
    prazo: null,
    status: ['0', 'open', 'pending', 'in-progress', 'homolog', 'recused', 'canceled'],
    periodo: null,
    data1: null,
    data2: null,
    tipoData: 'createdAt',
    onlyOwnerTasks: false,
    dueFilter: false,
    participants: [],
    participantFilter: 'participant', // or owner
    priority: null,
    tribunal: null,
    vara: null,
    comarca: null,
    cliente: null,
    processo: null,
    processoNumero: null
}

export default filters
